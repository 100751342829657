import React, { lazy, useEffect, useState } from 'react';
import Container from 'common/src/components/UI/Container';
import SectionWrapper from './impressum.style';
import { useIntl } from 'gatsby-plugin-react-intl';

const importContent = language =>
  lazy(() =>
    import(`./content/${language}`).catch(() => import(`./content/en`))
  );

const Impresum = () => {
  const isSSR = typeof window === 'undefined';
  const intl = useIntl();
  const [content, setContent] = useState();

  useEffect(() => {
    async function loadContent() {
      if (['de', 'en'].includes(intl.locale)) {
        const ContentView = await importContent(intl.locale);
        setContent(<ContentView />);
      }
    }
    loadContent();
  }, [intl.locale]);

  return (
    <SectionWrapper>
      <Container style={{ maxWidth: '800px', marginTop: '5rem' }}>
        {!isSSR && (
          <React.Suspense fallback="Loading content...">
            {content}
          </React.Suspense>
        )}
      </Container>
    </SectionWrapper>
  );
};

export default Impresum;
